import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { datacrudApiClient as client } from '../utils/apiClient';
import { useNavigate } from 'react-router-dom';
import { useRefreshJianList } from './useJianList';
import { sleep } from '../utils/sleep';
import { useSelectedOrCurrentYear, useSelectedYear } from './useYears';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getJian = async (jianId: string) => {
  const res = await client.GET(`/jians/{jianId}`, {
    params: { path: { jianId } },
  });
  if (res.error) {
    throw new Error(`Failed to GET /jians/${jianId} \n message: ${res.error.message}`);
  }
  return res.data;
};

const createJian = async (year: string | undefined) => {
  const res = await client.POST(`/jians`, {
    body: { year },
  });
  if (res.error) {
    throw new Error(`Failed to POST /jians \n message: ${res.error.message}`);
  }
  return res.data;
};

const createPatient = async (jianId: string) => {
  const res = await client.POST(`/jians/{jianId}/patient-info`, {
    params: { path: { jianId } },
  });
  if (res.error) {
    throw new Error(`Failed to POST /jians/{jianId}/patient-info \n message: ${res.error.message}`);
  }
  return res.data;
};

const deletePatient = async (jianId: string, patientInfoId: string) => {
  const res = await client.DELETE(`/jians/{jianId}/patient-info/{patientInfoId}`, {
    params: { path: { jianId, patientInfoId } },
  });
  if (res.error) {
    throw new Error(
      `Failed to DELETE /jians/{jianId}/patient-info/{patientInfoId} \n message: ${res.error.message}`
    );
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
// Jianを取得
export const useJian = (jianId: string | undefined) => {
  const { data } = useQuery({
    queryKey: ['jian', jianId],
    queryFn: async () => {
      return await getJian(jianId ?? '');
    },
    enabled: !!jianId,
  });

  return data;
};

// 新規事案を作成
export const useCreateJian = () => {
  const selectedYear = useSelectedYear();
  const navigate = useNavigate();
  const refreshJianList = useRefreshJianList();
  const year = useSelectedOrCurrentYear();
  const mutation = useMutation({
    mutationFn: async () => {
      const promise = createJian(selectedYear);
      toast.promise(promise, {
        loading: '新規事案を作成中...',
        success: '新規事案を作成しました。',
        error: '新規事案の作成に失敗しました。',
      });

      const res = await promise;
      if (res.jianId === '') {
        throw new Error('No jianId is returned on createJian');
      }
      const jian = await getJian(res.jianId); // TODO: dispatchInfoIdを`POST /jians`のレスポンスに含める

      refreshJianList();
      navigate(
        `/${year}/jian/${res.jianId}/dispatch-info/${jian.dispatchInfoId}?categoryName=出動概要`
      );
    },
  });

  return {
    isCreating: mutation.isLoading,
    createJian: mutation.mutateAsync,
  };
};

// 新規PatientInfoを作成
export const useCreatePatientInfo = (jianId: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const year = useSelectedOrCurrentYear();
  const mutation = useMutation({
    mutationFn: async () => {
      const promise = createPatient(jianId);
      toast.promise(promise, {
        loading: '新規傷病者を作成中...',
        success: '新規傷病者を作成しました。',
        error: '新規傷病者の作成に失敗しました。',
      });

      const res = await promise;
      if (res.patientInfoId === '') {
        throw new Error('No patientInfoId is returned on createPatient');
      }
      queryClient.invalidateQueries(['jian', jianId]);

      await sleep(800);
      navigate(`/${year}/jian/${jianId}/patient-info/${res.patientInfoId}?categoryName=基本情報`);
    },
  });

  return mutation.mutateAsync;
};

// PatientInfoを削除
export const useDeletePatientInfo = (jianId: string, patientInfoId: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const jian = useJian(jianId);
  const year = useSelectedOrCurrentYear();
  const firstPatientInfoId = jian?.patientInfos.find(
    (patientInfo) => patientInfo.patientInfoId !== patientInfoId
  )?.patientInfoId;

  const mutation = useMutation({
    mutationFn: async () => {
      if (window.confirm('傷病者を削除しますか？')) {
        const promise = deletePatient(jianId, patientInfoId);
        toast.promise(promise, {
          loading: '傷病者を削除中...',
          success: '傷病者を削除しました。',
          error: '傷病者の削除に失敗しました。',
        });

        await promise;
        await queryClient.invalidateQueries({ queryKey: ['jian', jianId], exact: true });

        if (firstPatientInfoId) {
          navigate(
            `/${year}/jian/${jianId}/patient-info/${firstPatientInfoId}?categoryName=基本情報`
          );
        } else {
          navigate(`/${year}/jian/${jianId}/patient-info`);
        }
      }
    },
  });

  return mutation.mutateAsync;
};
